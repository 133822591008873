<template>
  <v-container fluid>
    <Breadcrumbs />
    <v-row class="pa-0 ma-0">
      <v-flex xs12 sm12 md12>
        <v-card flat tile>
          <v-card-text>
            <v-row>
              <!-- for input-->
              <v-col cols="12" sm="4">
                <v-card class="fill-height secondarygrad rounded">
                  <v-card-title class="ma-0 py-2 px-4">
                    <h5
                      class="secondary--text text--lighten-2 font-weight-medium"
                    >
                      Add New
                    </h5>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Create your own product</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="product.title"
                      label="Name Of Product"
                      autofocus
                      @keyup="uppercase"
                    >
                    </v-text-field>

                    <v-text-field
                      v-model="product.sku"
                      label="SKU"
                      placeholder="sku"
                    >
                    </v-text-field>

                    <v-text-field
                      v-model="product.price"
                      type="number"
                      label="Price"
                      placeholder="Price"
                    >
                    </v-text-field>

                    <v-textarea
                      v-model="product.desc"
                      label="Description"
                      placeholder="(optional)"
                    >
                    </v-textarea>

                    <v-flex
                      xs12
                      sm12
                      md12
                      style="text-align: center !important"
                    >
                      <div align="center">
                        <v-color-picker
                          v-model="product.hex"
                          hide-mode-switch
                          hide-canvas
                          mode="hexa"
                          class="mx-auto transparent widthCustom"
                          style="max-width: 100% !important"
                        >
                        </v-color-picker>
                      </div>
                    </v-flex>
                    <v-btn
                      fill
                      block
                      color="primarygrad white--text rounded"
                      @click="add"
                    >
                      <v-icon left :size="23">add_circle</v-icon> Add
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="8">
                <v-card flat class="fill-height">
                  <v-card-title class="ma-0 py-2 px-4">
                    <h5
                      class="secondary--text text--lighten-2 font-weight-medium"
                    >
                      Product
                    </h5>
                  </v-card-title>
                  <v-card-text>
                    <v-row v-if="preloader" style="margin: 0px">
                      <v-card-text style="text-align: center !important">
                        <v-progress-circular
                          :width="3"
                          color="green"
                          indeterminate
                        ></v-progress-circular>
                      </v-card-text>
                    </v-row>
                    <v-row
                      v-else-if="productList.length > 0"
                      style="margin: 0px"
                    >
                      <v-flex
                        v-for="product in productList.filter((d) => {
                          return !d.system;
                        })"
                        :key="product._id"
                        xs12
                        sm6
                        md6
                        style="padding: 5px"
                      >
                        <v-card class="rounded fill-height">
                          <v-card-text style="margin:0px;">
                            <v-row style="margin: 0px">
                              <v-flex
                                xs10
                                sm10
                                md10
                                style="
                                  white-space: nowrap;
                                  width: 100px;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                <h3 class="px-2 font-weight-medium">
                                  <v-icon :color="product.hex"
                                    >fiber_manual_record</v-icon
                                  >
                                  {{ product.title }}
                                </h3>
                                <h5
                                  v-if="product.sku != 0"
                                  style="
                                    font-weight: 400;
                                    opacity: 0.6px;
                                    font-size: 13px;
                                    margin: 0px 0px 0px 40px;
                                    
                                  "
                                >
                                  {{ product.sku }}
                                </h5>
                                <h5
                                  v-if="product.price != 0"
                                  style="
                                    font-weight: 400;
                                    opacity: 0.6px;
                                    font-size: 13px;
                                    margin: 0px 0px 0px 40px;
                                    
                                  "
                                >
                                  {{ product.price }}
                                </h5>
                              </v-flex>
                              <v-flex
                                xs2
                                sm2
                                md2
                                style="
                                  padding: 0px;
                                  margin-top: -7px;
                                  text-align: right !important;
                                "
                              >
                                <v-menu
                                  origin="center center"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn small text fab v-on="on">
                                      <v-icon size="20">more_vert</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item @click="productEdit(product)">
                                      <v-list-item-title
                                        >Edit</v-list-item-title
                                      >
                                    </v-list-item>
                                    <v-list-item @click="del(product)">
                                      <v-list-item-title
                                        >Delete</v-list-item-title
                                      >
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-flex>
                            </v-row>
                          </v-card-text>
                        </v-card>

                        <!-- <v-card flat class="rounded inverted">
                          <v-card-title>
                            <v-icon :color="product.hex"
                              >fiber_manual_record</v-icon
                            >
                            <h6 class="font-weight-medium secondary--text pl-3">
                              {{ product.title }}
                            </h6>
                            <v-spacer></v-spacer>

                            <v-menu
                              origin="center center"
                              :close-on-content-click="false"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn small text fab v-on="on">
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="productEdit(product)">
                                  <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="del(product)">
                                  <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-card-title>
                        </v-card> -->
                      </v-flex>
                    </v-row>

                    <v-row v-else style="margin: 0px">
                      <v-card-text style="text-align: center !important">
                        <h5 class="secondary--text text--lighten-2">
                          No Product Found
                        </h5>
                      </v-card-text>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-row>

    <v-dialog v-model="dialogProductEdit" width="450">
      <v-card>
        <v-card-title class="primary white--text"> Edit Product </v-card-title>

        <v-card-text style="padding-top: 25px; padding-bottom: 25px">
          <v-flex xs12 sm12 md12>
            <v-text-field
              v-model="productEditedItem.title"
              label="Name"
              :rules="rulesProductname"
              :value="productEditedItem.title.toUpperCase()"
              @input="productEditedItem.title = $event.toUpperCase()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-textarea
              v-model="productEditedItem.desc"
              label="Description"
              placeholder="(optional)"
            ></v-textarea>
          </v-flex>

          <v-text-field
            v-model="productEditedItem.sku"
            label="SKU"
            placeholder="sku"
          >
          </v-text-field>

          <v-text-field
            v-model="productEditedItem.price"
            label="Price"
            placeholder="Price"
          >
          </v-text-field>

          <v-flex xs12 sm12 md12 style="text-align: center !important">
            <v-color-picker
              v-model="productEditedItem.hex"
              hide-mode-switch
              mode="hexa"
              class="mx-auto"
            ></v-color-picker>
          </v-flex>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="dialogProductEdit = false"
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="updateProductName"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const _ = require("lodash");
import fb from "../js/firebase";
import validator from "../js/validator.js";
import Breadcrumbs from "../components/breadcrumbs.vue";

export default {
  data() {
    return {
      dialogProductEdit: false,
      productEditedItem: {
        title: "",
        desc: "",
        hex: "",
        sku: "",
        price: "",
      },
      preloader: false,
      product: {
        title: "",
        desc: "",
        hex: "",
        sku: "",
        price: "",
      },
      rulesProductname: [
        (v) =>
          /^\w+$/.test(v) ||
          "Maximum Fifteen characters & Must Not Contain Special Characters Or Space",
      ],
      productList: [],
    };
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    // productList(v) {
    //   return this.$store.getters.productList;
    // },
  },
  watch: {
    // productList() {
    //   this.preloader = false;
    // },
  },
  created() {
    this.getProducts();
  },

  methods: {
    uppercase() {
      this.product.title = this.product.title.toUpperCase();
    },
    productEdit(item) {
      this.productEditedItem = item;
      this.dialogProductEdit = true;
    },
    updateProductName() {
      const self = this;

      if (this.productEditedItem.title == "") {
        return self.$swal({
          type: "error",
          text: "Please Enter Product Name",
        });
      }

      self.$http
        .patch(
          `${this.$store.state.ENDPOINT}/products/${self.productEditedItem.id}`,
          self.productEditedItem
        )
        .then(
          (response) => {
            self.dialogProductEdit = false;
            self.getProducts();
            self.$swal({
              type: "success",
              text: "Product Updated Successfully",
            });
          },
          (error) => {
            self.$swal({
              type: "warning",
              text: "Error occured. Please try again.",
            });
          }
        );
    },

    async getProducts() {
      var self = this;
      var filter = {
        where: {
          companyId: this.$store.getters.user.companyId,
        },
      };

      try {
        var response = await this.$http.get(
          `${self.$store.state.ENDPOINT}/products?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        );
        self.productList = response.body;

        self.loader = false;
      } catch (err) {
        console.log("Error", err);

        self.loader = false;

        self.$swal({
          type: "error",
          text: "Something went wrong. Please try again",
        });
      }
    },

    add() {
      if (this.product.title == "") {
        return self.$swal({
          type: "error",
          text: "Please Enter Product Name",
        });
      }

      this.product.title = this.product.title.toUpperCase().trim();
      this.preloader = true;
      this.product.companyId = this.$store.getters.user.companyId;

      this.$http
        .post(`${this.$store.state.ENDPOINT}/products`, this.product)
        .then((response) => {
          this.product = {};
          //   this.product.title = "";
          this.product.hex = "";
          this.product.desc = "";
          this.product.sku = "";
          this.product.price = "";
          this.preloader = false;
          this.getProducts();
          fb.log("create_products");
        })
        .catch((e) => {
          this.preloader = false;
          this.$swal({
            type: "error",
            text: "Sorry, same product is already present",
          });
        });
    },
    del(item) {
      this.preloader = true;

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Delete this product?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "cancel",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(`${this.$store.state.ENDPOINT}/products/${item.id}`)
              .then((response) => {
                this.preloader = false;
                this.getProducts();
                // this.$store.dispatch("getProducts", {
                //   companyId: this.$store.getters.user.companyId,
                // });
                fb.log("delete_product");
              })
              .catch((e) => {
                this.preloader = false;
                console.log(e);
              });
          }
          this.preloader = false;
        });
    },
  },
};
</script>

<style scoped>
.widthCustom {
  max-width: 600px !important;
}

.v-color-picker__canvas {
  width: 600px !important;
}
</style>
